<template>
  <div>
    <div v-if="!error" ref="chat"></div>
    <div v-else><h1>Keine Berechtigung</h1></div>
  </div>
</template>
<script>
import { externalChat } from "@/components/chatUpdate.js";
export default {
  components: {},
  props: {},
  data () {
    return {
      users: {},
      messages: [],
      user: atob(this.$route.params.user) * 1,
      error: false
    }
  },
  methods: {
    saveMsg(msg) {
      this.$flix_post({
        url: 'support_chats/new_msg',
        data: {
          ID: this.$route.params.chatID,
          msg: msg.msg,
          user: msg.userID
        },
        callback: function (ret) {
          if (!ret.data[0]){
            this.error = true
          }
        }.bind(this)
      })
    },
    loadChat() {
      this.chatData = {
        language: this.$route.params.lang,
        messages: this.messages,
        legend: Object.keys(this.users), // false | all | active | me | array [1,2]
        activeUser: this.user,
        users: this.users,
        onSubmit: function(msg) {
          this.saveMsg(msg)
        }.bind(this),
      };

      new externalChat(function() {
       }).run(this.chatData, this.$refs.chat);
    },
    urlify(text) {
      var urlRegex = /(https?:\/\/[^\s]+)/g;
      return text.replace(urlRegex, function(url) {
        return '<a href="' + url + '" target="_blank">' + url + '</a>';
      })
    },
    beautifyUsers () {

      Object.keys(this.users).forEach(function (k) {
        if (typeof this.users[k] !== "object") {
          return true
        }
        this.users[k].background = '#8dc044'
        this.users[k].color = 'black'
        this.users[k].avatar = 'https://bookingflix.com/assets/img/chat/customer_avatar.jpg'
        if(k * 1 !== 0) {
          this.users[k].background = 'lightgrey'
          this.users[k].color = 'black'
          this.users[k].avatar = 'https://bookingflix.com/assets/img/chat/flix_avatar.jpeg'
        }
      }.bind(this))

      if(Object.keys(this.users).indexOf(this.user) === -1) {
        if (typeof this.users[this.user] === "object") {
          return true
        }
        var user = {}
        user[this.user] = {
          name: 'Ich',
          background: 'lightgrey',
          color: 'black',
          avatar: 'https://bookingflix.com/assets/img/chat/flix_avatar.jpeg'
        }
      }
      this.users[this.user] = user[this.user]

    },
    beautifyMsg () {
      Object.keys(this.messages).forEach(function (k) {
        this.messages[k].msg = this.urlify(this.messages[k].msg)

        var view = Object.keys(this.users)
        if(this.messages[k].view.indexOf("user") === -1) {
          view.splice(view.indexOf("0"), 1)
        }
        if(this.messages[k].view.indexOf("bot") === -1) {
          view = ["0"]
        }
        this.messages[k].view = view
      }.bind(this))
    },
    mountChat () {
      this.$flix_post({
        url: 'support_chats/get',
        data: {
          ID: this.$route.params.chatID,
          user: this.user
        },
        callback: function (ret) {
          if (ret.data[0]) {
            this.users = ret.data[1].users
            this.beautifyUsers()
            this.messages = ret.data[1].msg
            this.beautifyMsg()
            this.loadChat()
          }
        }.bind(this)
      })
    }
  },
  mounted () {
    this.mountChat()
  },
  computed: {

  }
}
</script>
<style lang="sass">
  #jj_chat_area
    max-width: 800px
    margin: 0 auto
  #jj_chat_area .msg_area, #jj_chat_area .insert_area, #jj_chat_area .translation_area, #jj_chat_area .writing_area, #jj_chat_area .pause_area
    width: 100% !important
</style>

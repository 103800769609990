var externalChat = function(callback) {
  this.run = function(embed, ref) {
    this.ref = ref;
    this.loadChat(
      window,
      document,
      "script",
      "https://chat.bookingflix.com/vendor/embed.js",
      embed
    );
  };

  this.loadChat = function(a, b, c, d, e, f, g) {
    f = b.createElement(c);
    g = this.ref;
    f.async = 1;
    f.src = d;
    g.parentNode.replaceChild(f, g, b);
    f.onload = function() {
      new get_chat(14).build(f, e);
      callback(f, e);
    };
  };
};

export { externalChat };
